<template>
    <section id="performance-section" v-if="data != null">
        <div class="container performance-cointainer">
            <span class="performance">{{ data?.top_title }}</span>
            <div class="performance-text">
                <h2 v-html="data?.title"></h2>
                <p v-html="data?.text"></p>
            </div>
            <div class="performance-img desktop">
                <img :src="img" alt="" v-for="img in data?.imgs">
            </div>
            <div class="performance-slider mobile" ref="performance_slider">
                <div class="performance-swiper swiper">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="img in data?.imgs_m">
                            <img :src="img" alt="">
                        </div>
                    </div>
                    <div class="performance-pagination swiper-pagination"></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Swiper from 'swiper';

export default {
    props: {
        data: null,
    },
    mounted() {
        const interval = setInterval(() => {           
            if (this.$refs.performance_slider) {
                clearInterval(interval)
                setTimeout(() => {
                    const swiper = new Swiper('.performance-swiper', {
                        loop: true,
                        spaceBetween: 24,
                        pagination: {
                            el: '.performance-pagination',
                        },
                    });    
                }, 300);
            }
        }, 500); 
    }
}

</script>
<style>
    .uz_cyr .performance-text h2 {
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
    }

    .uz_cyr .performance-text p {
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
    }

    .performance-cointainer {
        margin-bottom: 180px;
    }

    .performance {
        display: block;
        color: var(--blue);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 124%;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        margin-bottom: 16px;
    }

    .performance-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 50px;
        margin-bottom: 55px;
    }
    
    .performance-text h2 {
        color: var(--gray);
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
    }

    .performance-text p {
        color: var(--gray);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
        letter-spacing: 0.32px;
        max-width: 560px;
        margin-top: 7px;
    }

    .performance-img {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
    }

    .performance-img img {
        width: 100%;
        height: auto;
        max-height: 494px;
        border-radius: 48px;
    }

    .uz .performance-text h2 {
        color: #242424;
        font-family: Montserrat;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 132%;
    }

    .uz .performance-text p {
        color: #242424;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
    }

    .uz .performance-text {
        gap: 25px;
    }

    .kz .performance-text h2 {
        font-size: 39px;
    }

    .kz .performance-text p {
        font-size: 15px;
        letter-spacing: 0;
    }

    @media screen and (max-width: 1300px) {
        .performance-img {
            gap: 20px;
        }
    }

    @media screen and (max-width: 1200px) {
        .kz .performance-text h2 {
            font-size: 22px;
        }

        .kz .performance-text p {
            font-size: 13px;
            letter-spacing: 0;
        }

        .performance-text {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 24px;
            margin-bottom: 24px;
        }

        .performance-text h2 {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .performance-img img {
            display: none;
        }

        .performance-img img:first-of-type {
            display: block;
            max-width: 400px;
            margin: 0px auto;
            border-radius: 32px;
        }

        .performance-text p {
            max-width: 100%;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
            margin-top: 12px;
        }

        .performance {
            display: block;
            margin-bottom: 8px;
            font-size: 14px;
        }

        .uz .performance-text h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .uz .performance-text p {
            color: #484848;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
        }

        .uz_cyr .performance-text h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
        }

        .uz_cyr .performance-text p {
            color: #484848;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
            letter-spacing: 0;
        }

        .performance-pagination {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 12px;
            position: relative;
            margin-top: 16px;
        }

        .performance-pagination .swiper-pagination-bullet {
            display: block;
            width: 24px;
            height: 4px;
            border-radius: 12px;
            background: #E0E0E0;
            transition: 0.8s;
        }

        .performance-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 64px;
            background: var(--blue);
        }

        .performance-swiper .swiper-slide {
            height: 481px;
            width: 100%;
            border-radius: 32px;
            overflow: hidden;
        }

        .performance-swiper .swiper-slide {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
</style>