<template>
    <section id="own-business-section" v-if="data != null">
        <div class="container own-container">
            <span class="top-span">{{ data?.top_title }}</span>
            <h2 v-html="data?.title"></h2>
            <div class="own-list">
                <div class="own-item" v-for="(step, index) in data?.card" :class="(index + 1) % 2 == 0 ? 'reverse' : ''">
                    <img class="desktop" :src="step.img" alt="">
                    <img class="mobile" :src="step.img_m" alt="">
                    <div class="own-item-content">
                        <div class="own-item-title" v-html="step.title">
                        </div>
                        <div class="own-item-text" v-html="step.text">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null,
    }
}
</script>
<style>
    .own-container h2 {
        margin-bottom: 48px;
    }

    .own-item {
        display: flex;
        flex-direction: row;
        gap: 40px;
        margin-bottom: 40px;
    }

    .own-item.reverse {
        flex-direction: row-reverse;
        margin-bottom: 0px;
    }

    .own-item img {
        border-radius: 48px;
        min-width: calc((100% - 40px) / 2);
        max-width: calc((100% - 40px) / 2);
        height: 520px;
        object-fit: cover;
    }

    .own-item-text {
        display: flex;
        flex-direction: column;
        max-width: 500px;
    }

    .own-item-text h3 {
        margin-bottom: 8px;
    }

    .own-item-title {
        font-size: 24px;
        line-height: 164%;
        font-weight: 700;
    }

    .own-item-content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        margin-top: 16px;
    }

    @media screen and (max-width: 1200px) {
        
        .kz .own-container h2 {
            font-size: 22px;
        }

        #own-business-section {
            margin-bottom: 96px;
        }

        .own-item {
            flex-direction: column;
            gap: 24px;
        }
        .own-item.reverse {
            flex-direction: column;
            gap: 24px;
        }

        .own-item {
            margin-bottom: 24px;
        }

        .own-item.reverse {
            margin-bottom: 0px;
        }

        .own-item img {
            width: 100%;
            height: auto;
            border-radius: 32px;
            max-width: 100%;
            min-width: 100%;
        }

        .own-item-text h3 {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
            letter-spacing: 0.32px;
        }

        .own-item-text p,
        .own-item-text ol,
        .own-item-text li {
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 164%;
        }

        .own-container h2 {
            margin-bottom: 36px;
            font-size: 24px;
        }

        .uz .own-container h2 {
            color: #242424;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 132%;
        }

        .own-item-text {
            gap: 0px;
            width: 100%;
        }

        .own-item-text h3 {
            margin-bottom: 8px;
        }

        .own-item-text ol {
            padding-left: 17px;
        }

        .uz_cyr .own-container h2 {
            font-size: 22px;
        }

        .kg .own-item-text p,
        .kg .own-item-text ol,
        .kg .own-item-text li {
            font-size: 11px;
        }

        .own-item-content {
            margin-top: 0px;
        }

        .own-item-content {
            gap: 8px;
        }

        .own-item-title {
            font-size: 16px;
            line-height: 19.84px;
        }
    }
</style>