<template>
    <section id="achievement">
        <div class="container">
            <div class="top-span" v-html="data?.top_title"></div>
            <h2 v-html="data?.title"></h2>
            <div class="achievement">
                <div class="achievement-img-wrapper">
                    <img class="achievement-img" :src="data?.img" />
                </div>
                <div class="achievement-info">
                    <div
                        class="achievement-info-text"
                        v-html="data?.text"
                    ></div>
                    <Button :value="data?.btn" btn_class="blue" @click="popupOpen"></Button>
                </div>
                <!-- <pre>
                    {{ data }}
                </pre> -->
            </div>
        </div>
    </section>
</template>

<script>
import Button from "../UI/Button.vue";

export default {
    components: {
        Button,
    },
    props: {
        data: null,
    },
    methods: {
        popupOpen() {
            this.$store.commit(
                "currentPlace",
                "Форма “Приглашаем амбициозных и уверенных”"
            );
            this.$store.commit("openPopup", true);
        },
        openRedirect() {
            this.$store.commit("openRedirect", true);
            this.$store.commit("setPopupLink", this.data?.items[1].link);
        },
    },
};
</script>

<style>
.achievement-img-wrapper {
    width: 550px;
}
.achievement-info .button {
    background-color: #0a97a0;
    color: #fff;
    padding: 16px 58px;
}
.achievement {
    display: flex;
    gap: 70px;
}

#achievement .top-span {
    margin-bottom: 16px;
}

#achievement h2 {
    margin-bottom: 80px;
}

.achievement-img {
    height: 100%;
    width: 100%;
    border-radius: 64px;
    object-fit: cover;
    object-position: center;
}

.achievement-info {
    max-width: 517px;
}

.achievement-info-text {
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 164%;
    text-align: left;
    margin: 3px 0 48px;
}

.achievement-info-text span {
    margin: 3px 0 43px;
    font-size: 18px;
    font-weight: 600;
    line-height: 29.52px;
}

.achievement-info-text a {
    color: var(--blue);
    text-decoration: underline;
}

.achievement-info-quantity {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 27px;
}

.achievement-info-quantity-column-number {
    font-family: Montserrat;
    font-size: 32px;
    font-weight: 500;
    line-height: 47.52px;
    text-align: left;
    padding: 0 0 3px 0;
    border-bottom: 1px solid #c2e8e1;
}

.achievement-info-quantity-column-number span {
    font-size: 12px;
    line-height: 164%;
    font-weight: 600;
    font-family: Montserrat;
    white-space: nowrap;
    margin-left: -2px;
}

.achievement-info-quantity-column-text {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.72px;
    padding: 8px 0 0 0;
    text-align: left;
}

.achievement-info-desc {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 29.52px;
    text-align: left;
    margin: 32px 0 48px;
}

.button.achievement-btn {
    background-color: #0a97a0;
    color: #fff;
    font-size: 14px;
    border-radius: 16px;
    /* width: 270px; */
    padding: 16px 58px;
    text-transform: none;
}

@media screen and (max-width: 1200px) {
    .achievement {
        flex-direction: column-reverse;
        gap: 32px;
    }

    .achievement-info-text {
        font-size: 12px;
        line-height: 19.68px;
        margin: 36px 0 40px;
    }

    .achievement-info-quantity {
        gap: 12px;
    }

    .achievement-info-quantity-column-number {
        font-size: 24px;
        line-height: 31.68px;
        padding: 0 0 4px 0;
    }

    .achievement-info-quantity-column-text {
        padding: 4px 0 0 0;
        font-size: 13px;
        font-weight: 500;
        line-height: 19.24px;
    }

    .achievement-info-desc {
        font-size: 14px;
        line-height: 22.96px;
        margin: 24px 0 40px;
    }
    .button.achievement-btn {
        width: 100%;
        font-size: 14px;
        font-weight: 700;
        line-height: 18.48px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
    }

    .achievement-img-wrapper {
        width: unset;
    } 

    .achievement-info-quantity-column {
        width: 100%;
    }

    .achievement-img {
        width: 100%;
        height: 320px;
    }

    .achievement-info-quantity-column-number {
        font-size: 20px;
        padding-bottom: 5px;
    }

    .achievement-info-quantity-column-number span {
        font-size: 10px;
        font-weight: 500;
        margin-left: 0px;
    }

    #achievement h2 {
        margin-bottom: 0px;
    }

    #achievement .top-span {
        margin-bottom: 8px;
    }
}
</style>
