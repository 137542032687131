<template>
    <section :class="this.$store.state.currentLang" id="main-section" v-if="data != null">
        <div class="main-container">
            <!-- <img class="desktop" :src="data?.bg"   alt="">
            <img class="mobile"  :src="data?.bg_m" alt=""> -->
            <img class="main-png desktop" :src="data?.bg" alt="">
            <img class="main-png mobile" :src="data?.bg_m" alt="">
            <div class="main-text">
                <div class="container">
                    <div class="top-text">
                        <span class="leader" v-html="data?.top_title"></span>
                        <span class="imy">{{ data?.title }}</span>
                        <p v-html="data?.text"></p>
                    </div>
                    <div class="bottom-text desktop">
                        <div class="bottom-text-item" v-for="item in data?.items">
                            <span>{{ item.title }}</span>
                            <p v-html="item.text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container bottom-text-container mobile">
            <div class="bottom-text">
                <div class="bottom-text-item" v-for="item in data?.items">
                    <span>{{ item.title }}</span>
                    <p v-html="item.text"></p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: {
        data: null,
    }
}
</script>
<style>
    #main-section {
        margin-bottom: 0;
        background-color: #CFDFDF;
        overflow: hidden;
        margin-bottom: 180px;
    }

    .main-png {
        position: absolute;
        bottom: -35%;
        right: 0;
        max-width: 680px;
        height: 150%;
        width: 100%;
        user-select: none;
        object-fit: cover;
    }
    .main-container {
        margin-top: 60px;
        width: 100%;
        height: max-content;
        position: relative;
        background-color: #CFDFDF;
        min-height: 750px;
        max-width: 1440px;
         margin: 0 auto;
    }

    .main-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        padding-top: 203px;
        padding-bottom: 55px;
    }

    .main-text .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 50px;
    }

    .top-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        max-width: 650px;
    }

    .leader {
        color: var(--blue);
        font-family: Montserrat;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 116%;
    }

    .imy {
        color: var(--dark_gray);
        font-family: Montserrat;
        font-size: 64px;
        font-style: normal;
        font-weight: 700;
        line-height: 124%;
        margin-bottom: 4px;
    }

    .top-text p {
        color: #363636;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 164%;
    }

    .bottom-text {
        display: flex;
        flex-direction: row;
        gap: 116px;
        max-width: 600px;
    }

    .bottom-text-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 6px;
    }

    .bottom-text-item span {
        color: var(--gray);
        font-family: Montserrat;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 132%;
    }

    .bottom-text-item p {
        color: #363636;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 142%;
    }

    .uz .top-text {
        max-width: 620px;
    }

    @media screen and (max-width: 1200px) {
        #main-section {
            margin-bottom: 96px;
        }

        .bottom-text.desktop {
            display: none;
        }
        .main-container {
            margin-top: 0px;
            overflow: hidden;
        }

        .main-container {
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            background-color: #d1e1e1;
            height: calc(100vh - 60px);
            min-height: 664px;
        }

        .main-text {
            position: relative;
            padding-top: 100px;
            margin-bottom: -255px;
        }

        .leader {
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 124%;
            width: 100%;
            margin-bottom: 2px;
        }

        .imy {
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 124%;
            width: 100%;
            margin-bottom: 12px;
        }

        .top-text p {
            text-align: center;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 164%;
            width: 100%;
        }

        .bottom-text {
            gap: 23px;
            justify-content: space-between;
            padding-top: 18px;
            max-width: 100%;
            position: relative;
            z-index: 2;
        }
        
        .bottom-text-container {
            background-color: #ffffff;
        }

        .bottom-text-item span {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 132%;
        }

        .bottom-text-item p {
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            line-height: 148%;
        }
        
        .bottom-text-item:first-of-type {
            max-width: max-content;
        }

        .top-text {
            margin: 0px auto;
            gap: 2px;
        }

        .imy {
            margin-bottom: 10px;
        }

        .bottom-text-item {
            gap: 4px;
        }
        .main-png {
            transform: translateY(140px) translateX(0);
            position: static;
            max-width: 390px;
            height: 100%;
            width: 100%;
            user-select: none;
            object-fit: cover;
        }
    }
</style>