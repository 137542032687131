<template>
    <section id="video-section">
        <div class="container video-section-container">
            <h2 v-html="data?.title"></h2>
            <div class="two-line desktop">
                <WhyTaoCard :data="item" :type_line="index == 0 ? 'one' : 'default'" v-for="item, index in data?.cards_top"/>
            </div>
            <div class="three-line desktop">
                <WhyTaoCard :data="item" :type_line="index == 0 ? 'one' : 'default'" v-for="item, index in data?.cards_bottom"/>
            </div>
            <div class="video">
                <Video :poster="data?.img" :data="data?.video"/>
            </div>
            <WhyTaoCard class="mobile" :data="data?.cards_bottom[1]" type_line="two"/>
            <div class="video-section-slider mobile">
                <div class="video-section-swiper swiper" ref="video_section_slider">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide" v-for="item in data?.cards_top">
                            <WhyTaoCard :data="item" type_line="two"/>
                        </div>
                        <template v-for="item, index in data?.cards_bottom">
                            <div class="swiper-slide" v-if="index != 1">
                                <WhyTaoCard :data="item" type_line="two"/>
                            </div>
                        </template>
                    </div>
                    <div class="video-section-pagination swiper-pagination"></div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Video from '@/components/UI/Video.vue'; 
import WhyTaoCard from '../UI/WhyTaoCard.vue'; 
import Swiper from 'swiper';
const video_section_slider = ref(null)

const props = defineProps({
    data: null,
})

onMounted(() => {
    const interval = setInterval(() => {           
        if (video_section_slider.value) {
            clearInterval(interval)
            setTimeout(() => {
                const video_swiper = new Swiper('.video-section-swiper', {
                    loop: true,
                    spaceBetween: 24,
                    pagination: {
                        el: '.video-section-pagination',
                    },
                });    
            }, 300);
        }
    }, 500);  
})
</script>
<style>
    .video {
        width: 100%;
        /* height: 500px; */
        overflow: hidden;
        border-radius: 24px;
    }

    .video-section-container {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .two-line {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 374px;
        gap: 40px;
    }

    .three-line {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 374px;
        gap: 40px;
    }

    .why-tao__card-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px;
        user-select: none;
        pointer-events: none;
    }

    #video-section .why-tao__card-icon {
        display: none;
    }

    #video-section .why-tao__card {
        display: flex;
        justify-content: flex-end;
    }

    #video-section .why-tao__card.mobile {
        display: none;
    }

    .video-section-container h2 {
        margin-bottom: 8px;
    }

    @media screen and (max-width: 1200px) {
        .two-line,
        .three-line {
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        .video {
            border-radius: 32px;
        }

        #video-section .why-tao__card.mobile {
            height: 292px;
            display: flex;
            align-items: flex-start;
        }

        .video-section-swiper .swiper-wrapper {
            height: 300px;
            /* width: 100% */
        }

        .video-section-pagination {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 12px;
            position: relative;
            margin-top: 16px;
        }

        .video-section-pagination .swiper-pagination-bullet {
            display: block;
            width: 24px;
            height: 4px;
            border-radius: 12px;
            background: #E0E0E0;
            transition: 0.8s;
        }

        .video-section-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
            width: 64px;
            background: var(--blue);
        }

        .video-section-container {
            gap: 24px;
        }

        #video-section .why-tao__card .why-tao__card-text {
            height: auto;
        }
        .video-section-container h2 {
            margin-bottom: 12px;
        }
    }
</style>